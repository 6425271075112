import React from "react";
import Footer from '../Components/Footer'
import NaviBarv2 from '../Components/NaviBarv2'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles.css";
import {Container, Row, Col, Card, ListGroup, ListGroupItem, Button, Tab, Nav,
OverlayTrigger, Popover, Placeholder} from 'react-bootstrap';
import conf_ModernProblemsOfInverseProblems2 from "../images/conf/conf_ModernProblemsOfInverseProblems2.png"


export function ModernProblemsOfInverseProblems () {
  return (
        <>
    <NaviBarv2 />
    <Container>
    <div><img src={conf_ModernProblemsOfInverseProblems2} align="left" width={500} ></img></div>
    <div class="row my-4 ps-4"><h2>Международная научная конференция «Современные проблемы обратных задач».</h2></div>
    <div class="row ps-4 my-4"><p class="lead"></p></div>
    <div class="row ps-4 my-4"><p class="lead"></p></div>
    <div class="row ps-4 my-4"><p class="lead"></p></div>
    <div class="row ps-4 my-4"><p class="lead"></p></div>
    <div class="row ps-4 my-4"><p class="lead"></p></div>
    <div class="row ps-4 my-4"><p class="lead"></p></div>
    <hr></hr>
    <div class="row ps-4 my-4"><p class="lead">С 3 по 5 октября в Институте математики им. С.Л. Соболева СО РАН пройдёт С 3 по 5 октября в Институте математики им. С.Л. Соболева СО РАН пройдёт Международная научная конференция «Современные проблемы обратных задач».</p></div>
    <div class="row ps-4 my-4"><p class="lead"><h3>Организационный комитет:</h3></p></div>
    <div class="row ps-4 my-4"><p class="lead"><b>Председатель:</b> д.ф.-.м.н., профессор РАН М.А. Шишленин. <br></br> <b>Зам. председателя:</b> д.ф.-м.н. О.И. Криворотько, к.ф.-м.н. Н.С. Новиков.<br></br><b>Ученый секретарь:</b> А.И. Глухов.<br></br><b>Оргкомитет:</b> Н.Ю. Зятьков, Т.А. Звонарева, П.С. Сурнин, А.В. Неверов, А.В. Губер.<br></br><b>Почта оргкомитета:</b> tcmiip@yandex.ru</p></div>
    <div class="row ps-4 my-4"><p class="lead"><h3>Программный комитет:</h3></p></div>
    <div class="row ps-4 my-4"><p class="lead"><b>Сопредседатели Программного комитета: </b>член-корр. РАН С.И. Кабанихин, Институт математики им. С.Л. Соболева СО РАН, Новосибирск, Россия;<br></br>
профессор РАН М.А. Шишленин, Институт математики им. С.Л. Соболева СО РАН, Новосибирск, Россия;<br></br>
д.ф.-м.н. О.И. Криворотько, Международный математический центр, Институт математики им. С.Л. Соболева СО РАН, Новосибирск, Россия.<br></br>
<b>Ученый секретарь: </b>П.С. Сурнин, Институт математики им. С.Л. Соболева СО РАН, Новосибирск, Россия.<br></br>
<b>Программный комитет: </b>академик РАН В.И. Бердышев, Институт математики и механики УрО РАН, Екатеринбург, Россия;<br></br>
академик РАН М.А. Гузев, Институт прикладной математики ДВО РАН, Владивосток, Россия;<br></br>
академик РАН В.П. Дымников, Институт вычислительной математики им. Г.И. Марчука РАН, Москва, Россия;<br></br>
академик РАН Ю.Г. Евтушенко, ФИЦ «Информатика и управление» РАН, Москва, Россия;<br></br>
академик НАН РК Т.Ш. Кальменов, Центр физико-математических исследований МОН РК, Алматы, Казахстан;<br></br>
академик Н.А. Колчанов, Институт цитологии и генетики СО РАН, Новосибирск, Россия;<br></br>
академик РАН Н.Ю. Лукоянов, Институт математики и механики УрО РАН, Екатеринбург, Россия;<br></br>
академик РАН В.Г. Романов, Институт математики им. С.Л. Соболева СО РАН, Новосибирск, Россия;<br></br>
академик РАН Г.И. Савин, Московский государственный университет им. М.В. Ломоносова, Москва, Россия;<br></br>
академик РАН Р.З. Сагдеев, Международный томографический центр СО РАН, Новосибирск, Россия;<br></br>
академик РАН В.А. Садовничий, Московский государственный университет им. М.В. Ломоносова, Москва, Россия;<br></br>
академик РАН Е.Е. Тыртышников, Институт вычислительной математики РАН, Москва, Россия;<br></br>
академик РАН М.П. Федорук, Новосибирский государственный университет, Новосибирск, Россия;<br></br>
академик РАН Б.Н. Четверушкин, Институт прикладной математики им. М.В. Келдыша РАН, Москва, Россия;<br></br>
академик РАН А.А. Шананин, Московский физико-технический институт, Москва, Россия;<br></br>
академик РАН М.И. Эпов, Институт нефтегазовой геологии и геофизики им. А.А. Трофимука СО РАН, Новосибирск, Россия;<br></br>
член-корр. РАН В.В. Васин, Институт математики и механики УрО РАН, Екатеринбург, Россия;<br></br>
член-корр. РАН В.Н. Глинских, Институт нефтегазовой геологии и геофизики им. А.А. Трофимука СО РАН, Новосибирск, Россия;<br></br>
член-корр. РАН С.Н. Лебедев, Российский федеральный ядерный центр - Всероссийский научно-исследовательский институт технической физики имени академика Е. И. Забабахина, Снежинск, Россия;<br></br>
член-корр. РАН Г.А. Михайлов, Институт вычислительной математики и математической геофизики СО РАН, Новосибирск, Россия;<br></br>
член-корр. РАН И.Б. Петров, Московский физико-технический институт, Москва, Россия;<br></br>
член-корр. РАН Р.М. Шагалиев, Российский федеральный ядерный центр - Всероссийский научно-исследовательский институт экспериментальной физики, Саров, Россия;<br></br>
профессор М.А. Бектемесов, Казахстанский национальный педагогический университет им Абая, Алматы, Казахстан;<br></br>
профессор М.И. Белишев, Санкт-Петербургское отделение Математического института им. В. А. Стеклова РАН, Санкт-Петербург, Россия;<br></br>
профессор В.И. Васильев, Северо-восточный федеральный университет им. М.К. Аммосова, Якутск, Россия;<br></br>
профессор РАН К.В. Воронцов, Московский физико-технический институт, Москва, Россия;<br></br>
профессор С.К. Голушко, Новосибирский государственный университет, Новосибирск, Россия;<br></br>
профессор И.Н. Ельцов, Институт вычислительной математики и математической геофизики СО РАН, Новосибирск, Россия;<br></br>
профессор Г.Н. Ерохин, Балтийский федеральный университет им. И. Канта, Калининград, Россия;<br></br>
профессор К.Т. Искаков, Евразийский национальный университет им. Л.Н. Гумилева, Астана, Казахстан;<br></br>
профессор М.Ю. Кокурин, Марийский государственный университет, Йошкар-Ола, Россия;<br></br>
профессор И.В. Коптюг, Международный томографический центр СО РАН, Новосибирск, Россия;<br></br>
профессор Г.М. Курамшина, Московский государственный университет им. М.В. Ломоносова, Москва, Россия;<br></br>
профессор М.М. Лаврентьев, Новосибирский государственный университет, Новосибирск, Россия;<br></br>
профессор В.И. Максимов, Институт математики и механики УрО РАН, Екатеринбург, Россия;<br></br>
профессор РАН М.А. Марченко, Институт вычислительной математики и математической геофизики СО РАН, Новосибирск, Россия;<br></br>
профессор РАН И.В. Оселедец, Сколковский институт науки и технологий, Москва, Россия;<br></br>
профессор В.В. Пененко, Институт вычислительной математики и математической геофизики СО РАН, Новосибирск, Россия;<br></br>
профессор В.В. Пикалов, Институт теоретической и прикладной механики им. С.А. Христиановича СО РАН, Новосибирск, Россия;<br></br>
профессор К.К. Сабельфельд, Институт вычислительной математики и математической геофизики СО РАН, Новосибирск, Россия;<br></br>
профессор РАН Д.Н. Сидоров, Институт систем энергетики им. Л.А. Мелентьева СО РАН,Иркутск, Россия;<br></br>
профессор В.А. Юрко, Саратовский государственный университет, Саратов, Россия;<br></br>
профессор А.Г. Ягола, Московский государственный университет им. М.В. Ломоносова, Москва, Россия;<br></br>
д.ф.-м.н. Г.А. Бочаров, Институт вычислительной математики РАН, Москва, Россия;<br></br>
д.ф.-м.н. А.В. Гасников, Московский физико-технический институт, Москва, Россия;<br></br>
д.ф.-м.н. А.С. Козелков, Российский федеральный ядерный центр - Всероссийский научно-исследовательский институт экспериментальной физики, Саров, Россия;<br></br>
к.ф.-м.н. С.Г. Казанцев, Институт математики им. С.Л. Соболева СО РАН, Новосибирск, Россия;<br></br>
к.ф.-м.н. Д.В. Лукьяненко, Московский государственный университет им. М.В. Ломоносова, Москва, Россия;<br></br>
к.ф.-м.н. В.С. Михайлов, Санкт-Петербургское отделение Математического института им. В. А. Стеклова РАН, Санкт-Петербург, Россия;<br></br>
к.ф.-м.н. Н.С. Новиков, Институт математики им. С.Л. Соболева СО РАН, Новосибирск, Россия;<br></br>
Professor H. Ammari, ETH Zürich, Zürich, Switzerland;<br></br>
Professor A. Baklanov, World Meteorological Organization, Geneva, Switzerland;<br></br>
Professor A.L. Bougheim, Kansas State University, Kansas, USA;<br></br>
Professor J. Cheng, Fudan University, Shanghai, China;<br></br>
Professor Ya. Efendiev, Texas A&M University, College Station, USA;<br></br>
Professor D.N. Hao, Institute of Mathematics, Hanoi, Vietnam;<br></br>
Professor A. Hasanoglu, Izmit University, Izmit, Turkey;<br></br>
Professor T. Hohage, University of Göttingen, Göttingen, Germany;<br></br>
Professor M. Ikehata, Hiroshima University, Hiroshima, Japan;<br></br>
Professor M. Klibanov, University of North Carolina at Charlotte,Charlotte, USA;<br></br>
Professor D. Lesnic, University of Leeds, Leeds, UK;<br></br>
Professor J. Liu, Southeast University, Nanjing, China;<br></br>
Professor A. Louis, Saarland University, Saarbrücken, Germany;<br></br>
Professor R. Novikov, Centre de Mathématiques Appliquées, Ecole Polytechnique, France;<br></br>
Professor O. Scherzer, Johann Radon Institute for Computational and Applied Mathematics, Vienna, Austria;<br></br>
Professor Y. Wang, Institute of Geology and Geophysics, Beijing, China;<br></br>
Professor Ye Zhang, MSU-BIT University, Shenzhen, China;<br></br>
Professor Sh. Zhang, Tianjin University of Finance and Economics,Tianjin, China;<br></br>
Professor J. Zou, Chinese University of Hong Kong, Hong Kong, China.</p></div>
    <div class="row ps-4 my-4"><p class="lead">Более подробная информация указана на <a rel="noopener" target="_blank" href="http://conf.nsc.ru/mcip2024/general_info">сайте конференции</a>.</p></div>
    <div class="row ps-4 my-4"><p>09.08.2024</p></div>
    </Container>
    <Footer />
    </>
    
    )}